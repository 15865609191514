import React, {useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router-dom';
import api from "./axiosInstance";
import {faCode, faEllipsisVertical, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Player} from "@lottiefiles/react-lottie-player";
import Dialog from "./Dialogs/Dialog";
import EmbedDialog from "./Dialogs/Embed/EmbedDialog";

const TemplatesList = () => {
    const { category } = useParams();
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showDropdown, setShowDropdown] = useState(null);
    const dropdownRef = useRef(null);
    const playerRefs = useRef({});
    const [hoveredTemplateId, setHoveredTemplateId] = useState(null);
    const [isEmbedDialogOpen, setEmbedDialogOpen] = useState(false);
    const selectedTemplateRef = useRef(null);

    const openEmbedDialog = (templateId) => {
        selectedTemplateRef.current = templateId;
        setEmbedDialogOpen(true);
    };

    const closeEmbedDialog = () => {
        selectedTemplateRef.current = null;
        setEmbedDialogOpen(false);
    };

    useEffect(() => {
        const fetchTemplates = async () => {
            setLoading(true);
            try {
                const res = await api.get(`/templates/category/${encodeURIComponent(category)}`, {
                    withCredentials: true,
                });
                setTemplates(res.data);
            } catch (err) {
                console.error('Error fetching templates:', err);
                setError('Error loading Templates!');
            } finally {
                setLoading(false);
            }
        };

        if (category) {
            fetchTemplates().then();
        }
    }, [category]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleDropdownToggle = (templateId) => {
        setShowDropdown(showDropdown === templateId ? null : templateId);
    };

    const handleDelete = async (templateId) => {
        try {
            const res = await api.delete(`/templates/${templateId}`, {
                withCredentials: true
            });
            console.log(res.data.msg);
            setTemplates(templates.filter(template => template._id !== templateId));
        } catch (err) {
            console.error('Error deleting Template:', err);
        }
    };

    const handleEdit = (templateId) => {
        console.log(`Bearbeiten Template: ${templateId}`);

    };

    const handleMouseEnter = (templateId) => {
        setHoveredTemplateId(templateId);
        if (playerRefs.current[templateId]) {
            playerRefs.current[templateId].play();
        }
    };

    const handleMouseLeave = (templateId) => {
        setHoveredTemplateId(null);
        if (playerRefs.current[templateId]) {
            playerRefs.current[templateId].pause();
        }
    };

    return (
        <div className={`container`}>
            <h2>Templates for Category {category}</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    <Dialog isOpen={isEmbedDialogOpen} onClose={closeEmbedDialog} type="embed" templateId={selectedTemplateRef.current}>
                        {isEmbedDialogOpen && selectedTemplateRef.current && (
                            <EmbedDialog templateId={selectedTemplateRef.current} onClose={closeEmbedDialog} />
                        )}
                    </Dialog>
                    <div className="template-list">
                    {templates.map(template => (
                        <div key={template.id} className="template-item" onMouseEnter={() => handleMouseEnter(template._id)}
                             onMouseLeave={() => handleMouseLeave(template._id)}>
                            <div className="template-item-header">
                                <h2>{template.name}</h2>
                                <FontAwesomeIcon className="template-card-more" icon={faEllipsisVertical} onClick={() => handleDropdownToggle(template._id)} />
                                {showDropdown === template._id && (
                                    <div className="template-card-dropdown-menu" ref={dropdownRef}>
                                        {/*<button onClick={() => handleEdit(template._id)}>Edit in Ferryman</button>*/}
                                        <button className="delete-button" onClick={() => handleDelete(template._id)}>
                                            <FontAwesomeIcon icon={faTrash} /> Delete
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="template-item-body">
                                <div className="template-item-preview"><Player
                                    ref={el => playerRefs.current[template._id] = el}
                                    src={template.data.templateJson}
                                    style={{ height: 'auto', width: '100%' }}
                                    loop
                                /></div>
                                <div className="template-item-infos">
                                    {template.description && (<div>{template.description}</div>)}
                                    {template.createdAt && (
                                        <div><strong>Created: </strong>{new Date(template.createdAt).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: 'long',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}</div>
                                    )}
                                    {template.updatedAt && (
                                        <div><strong>Last Edited: </strong>{new Date(template.updatedAt).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: 'long',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}</div>
                                    )}

                                    {template.tags && (
                                        <div className="template-item-taglist">
                                            {template.tags.map(tag => (
                                                <div key={tag} className="template-item-tag">{tag}</div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="template-item-buttons">
                                    <button onClick={() => {
                                        const ferrymanUrl = `https://demo.ferryman.streamshapers.com?templateId=${template._id}`;
                                        window.open(ferrymanUrl, '_blank');
                                    }}>
                                        Open in Ferryman
                                    </button>
                                    <button onClick={() => openEmbedDialog(template._id)}>
                                        <FontAwesomeIcon icon={faCode}/> Generate Embed
                                    </button>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                </>
            )}
        </div>
    );
};

export default TemplatesList;
