import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import Dashboard from './Dashboard/Dashboard';
import "./App.css";
import Header from "./Header";
import Settings from "./Settings/Settings";
import {GlobalStateProvider} from "./Context/GlobalStateContext";
import {ThemeProvider} from "./Theme/ThemeContext";
import TemplatesList from './TemplatesList';


const App = () => {
    return (
        <Router>
                <GlobalStateProvider>
                    <ThemeProvider>
                        <Header/>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/dashboard" element={<PrivateRoute/>}>
                                <Route path="/dashboard" element={<Dashboard/>}/>
                            </Route>
                            <Route path="/settings" element={<PrivateRoute/>}>
                                <Route path="/settings" element={<Settings/>}/>
                            </Route>
                            <Route path="/templates/:category" element={<PrivateRoute />}>
                                <Route path="/templates/:category" element={<TemplatesList />} />
                            </Route>

                        </Routes>
                    </ThemeProvider>
                </GlobalStateProvider>
        </Router>
    );
};

export default App;
