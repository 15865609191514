import React, { useContext, useState } from 'react';
import AuthContext from '../Context/AuthContext';
import {Link} from "react-router-dom";

const CategoriesList = () => {
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    return (
        <div className="categories-list-component">
            <div className="categories-grid-header">
                <div><h2>Your Categories</h2></div>
                <div></div>
            </div>
            <div className="categories-grid">
                {loading ? (
                    <p>Loading Categories...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    user.categories.map(category => (
                        <Link key={category} to={`/templates/${encodeURIComponent(category)}`} className="category-link">
                            {category}
                        </Link>
                    ))
                )}
            </div>
        </div>
    );
};

export default CategoriesList;
