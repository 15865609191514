import React, {useContext, useEffect, useState} from "react";
import {GlobalStateContext} from "../../Context/GlobalStateContext";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function DeleteDialog() {
    const {theme, ferrymanVersion} = useContext(GlobalStateContext);

    return (<>
            <div>

            </div>
        </>
    )
        ;
}

export default DeleteDialog;