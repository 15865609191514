import React, {useContext, useEffect, useState} from "react";
import api from "../../axiosInstance";
import {GlobalStateContext} from "../../Context/GlobalStateContext";

function EmbedDialog({ templateId, onClose }) {
    const {serverUrl} = useContext(GlobalStateContext);
    const [embedCode, setEmbedCode] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        generateEmbedLink().then();
    }, []);

    const generateEmbedLink = async () => {
        try {
            const res = await api.post('/templates/generate-token', { templateId }, {
                withCredentials: true,
            });
            const token = res.data.token;
            const embedUrl = `${serverUrl}/templates/embed/${templateId}?token=${token}`;
            const iframeCode = `<iframe src="${embedUrl}" width="800" height="600" frameborder="0" allowfullscreen></iframe>`;
            setEmbedCode(iframeCode);
        } catch (err) {
            console.error('Error generating embed link:', err);
        }
    };


    const copyToClipboard = () => {
        navigator.clipboard.writeText(embedCode).then(() => {
            alert('Embed code copied to clipboard!');
        }).catch(err => {
            console.error('Error copying embed code:', err);
        });
    };

    return (
        <div className="embed-dialog">
            <h2>Generate Embed Link</h2>
            {loading ? (
                <p>Generating embed link...</p>
            ) : (
                <>
                    {embedCode ? (
                        <>
                            <textarea value={embedCode} readOnly rows={4} cols={50} />
                            <button onClick={copyToClipboard}>Copy to Clipboard</button>
                        </>
                    ) : (
                        <button onClick={generateEmbedLink}>Generate Embed Code</button>
                    )}
                </>
            )}
        </div>
    );
}

export default EmbedDialog;
