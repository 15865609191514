import React, {useContext} from 'react';
import LatestTemplatesGrid from "./LatestTemplatesGrid";
import {GlobalStateContext} from "../Context/GlobalStateContext";
import AuthContext from "../Context/AuthContext";
import {useNavigate} from "react-router-dom";
import CategoriesList from "./CategoriesList";

const Dashboard = () => {
    const {error} = useContext(GlobalStateContext);
    const {user, loading} = useContext(AuthContext);
    const navigate = useNavigate();

    if (!user && !loading) {
        navigate('/login');
        return;
    }

    return (
        <div className="container">
            <h1>Dashboard</h1>
            {error && <div style={{color: 'red'}}>{error}</div>}
            {user ? (
                <>
                    <div>
                        <h2>Welcome, {user.username}!</h2>
                    </div>
                    <LatestTemplatesGrid/>
                    <CategoriesList/>
                </>
            ) : (
                <p>No user data available.</p>
            )}
        </div>
    );
};

export default Dashboard;
